import './rejectValidationFlow.scss';
var htmlContent = require('./rejectValidationFlow.html');
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { HttpService } from '../../core/http/http';
import { ToastService } from '../../shared/services/toastService';
import { Validator } from '../../core/validator/validator';
import { AuthenticationService } from '../../shared/services/authenticationService';
import { FlowType } from '../../config/enums';
import { TranslationService } from '../../core/translation/translation';

const renderMarkup = () => htmlContent;

export class RejectValidationFlowComponent extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this._options = options || new RejectValidationFlowOptions();
    this.httpService = new HttpService();
    this.toastService = new ToastService();
    this.authenticationService = new AuthenticationService();
    this.value = null;
  }

  onOpened() {
    this.init();
  }

  init() {
    this.rejectButton = this.parentModal.container.find('#reject');
    this.parentModal.container.find('#cancel').click(() => this.cancel());
    this.rejectButton.click(() => this.reject());
    this._validator = new Validator(this.parentModal.container);
    this._validator.validate();
    this._subtitle = this.parentModal.container.find('.subtitle');
    this._rejectButton = this.parentModal.container.find('.buttons #reject .txt');
    if (this._options.flowType === FlowType.Budget) {
      this._subtitle.text(TranslationService.instance.translate('bd-modal-reject-subtitle'));
      this._rejectButton.text(TranslationService.instance.translate('bd-modal-reject-btn-reject'));
    } else if (this._options.flowType === FlowType.Request) {
      this._subtitle.text(TranslationService.instance.translate('requests-modal-reject-subtitle'));
      this._rejectButton.text(
        TranslationService.instance.translate('requests-modal-reject-btn-reject')
      );
    }
  }

  cancel() {
    this.value = null;
    this.parentModal.close();
  }

  reject() {
    if (!this._validator.isValid) {
      return;
    }

    this.parentModal.toggleLoadingIcon(true);
    let body = {
      idAprobacion: this._options.approvalId,
      idResponsable: this.authenticationService.getAuthenticatedUser().idUsuario,
      comentarios: this.parentModal.container.find('#flow-reject-form-control').val(),
      usuarioActualizacion: this.authenticationService.getAuthenticatedUser().login
    };
    this.httpService
      .post(this.httpService.config.rejectBudget, body)
      .then(response => {
        this.value = { rejected: true };
        this.parentModal.close();
      })
      .catch(error => {
        if(error.message == null || error.message.indexOf("innerHTML") == -1 ){
          this.toastService.exception(error.title);
          console.error(error);
        }
      })
      .finally(() => {
        this.parentModal.toggleLoadingIcon(false);
      });
  }

  get result() {
    return this.value;
  }
}

export class RejectValidationFlowOptions {
  approvalId = 0;
  /**
   * Flow approval for a budget or request
   */
  flowType = null;
}
