import './budget-detail.scss';
var htmlContent = require('./budget-detail.html');
import $ from '../../core/jquery/jquery-global';
import { Component } from '../../shared/components/base-component';
import { BudgetTree, BudgetTreeOptions } from '../../components/budget-tree/budgetTree';
import { ViewsLoader } from '../../core/views/views-loader';

import { ModalComponent } from '../../shared/components/modal';
import { HttpService } from '../../core/http/http';
import { BudgetManager, BudgetLine } from '../../components/budget-tree/budget-manager';
import {
  UnitsEnums,
  BudgetStatusEnum,
  BudgetStatusClassesEnum,
  BudgetFlowStatusClassesEnum,
  RequestFlowStatusClassesEnum,
  BudgetStatusNameEnum,
  FlowStatusEnum,
  FlowType
} from '../../config/enums';
import { ToastService } from '../../shared/services/toastService';

import {
  RejectValidationFlowComponent,
  RejectValidationFlowOptions
} from '../../components/reject-validation-flow/rejectValidationFlow';

import {
  ApproveValidationFlowComponent,
  ApproveValidationFlowOptions
} from '../../components/approve-validation-flow/approveValidationFlow';

import {
  RequestFlowApprovalComponent,
  RequestFlowApprovalOptions
} from '../../components/request-flow-approval/requestFlowApproval';
import { QuitWithoutSaveComponent } from '../../components/quit-without-save/quitWithoutSave';
import { Router } from '../../core/router/router';
import { DatePicker } from '../../components/date-picker/datePicker';
import { Spinner } from '../../shared/components/spinner/spinner';
import { TranslationService } from '../../core/translation/translation';
import { AuthenticationService } from '../../shared/services/authenticationService';
import { ConfigService } from '../../shared/services/config';

const renderMarkup = () => htmlContent;

export class BudgetDetailPage extends Component {
  constructor(params) {
    super(renderMarkup());
    $(document).ready(() => {
      let selectNav = document.querySelectorAll('.links-item');
      let i = 0;
      selectNav.forEach(e => {
        if (i == 0) {
          $(e).addClass('active');
          i++;
        } else {
          $(e).removeClass('active');
          i++;
        }
      });
      this.budgetTree = null;
      this.httpService = new HttpService();
      this.toastService = new ToastService();
      this.viewsLoader = new ViewsLoader();
      this.authenticationService = new AuthenticationService();
      this.router = new Router();
      this.isDirty = false;
      this._title = null;
      this._isLocalCurrency = true;
      this._allowPhysicalSign = null;
      this._init(params.id);
      this.canApproveOrReject = false;
      //$('.go-back').click(() => {if(window.history.length > 1) {window.history.back()}});
    });
  }

  _init(budgetId) {
    this.router.userHasChange.listen(event => this.confirmQuitWithoutSave(event));
    //Destroy listener before leave the component if not is listening multiple times
    this.router.onBeforeNavigation.listen(event => {
      this.router.onBeforeNavigation.destroy();
      this.router.userHasChange.destroy();
    });
    $(window).on('beforeunload', () => {
      if (this.isDirty) {
        return TranslationService.instance.translate('msgLeavePageWithChages');
      }
    });

    this.loadData(budgetId);
  }

  loadData(budgetId) {
    $('#loader-container').removeClass('hidden');
    Promise.all([this.loadBudget(budgetId), this.loadApprovalFlow(budgetId), this._loadConfig()])
      .then(() => {
        this.buildPage();

        this._renderApprovalFlow(budgetId);
        $(window).resize(() => {
          this._renderApprovalFlow(budgetId);
        });
      })
      .finally(() => $('#loader-container').addClass('hidden'));
  }

  loadBudget(budgetId) {
    return new Promise((resolve, reject) => {
      this.viewsLoader.loadView(new Spinner('big'), document.getElementById('loader-container'));
      this.httpService
        .get(
          `${this.httpService.config.loadBudgetDetails}/${budgetId}/${this.authenticationService.getAuthenticatedUser().login
          }`
        )
        .then(response => {
          this._budgetResponse = response;
          resolve();
        })
        .catch(error => {
          this.toastService.exception(error.title);
          console.error(error);
          reject();

          location.assign('/#budgets');
        });
    });
  }

  buildPage() {
    this.buildTitle();
    this.buildHead();
    this.buildContent();
    this.enableButtons();
  }

  loadApprovalFlow(budgetId) {
    return new Promise((resolve, reject) => {
      this.httpService
        .get(
          `${this.httpService.config.loadBudgetApprovalFlow
          }?idPresupuesto=${budgetId}&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login
          }`
        )
        .then(response => {
          this._approvalFlowResponse = response;
          resolve();
        })
        .catch(error => {
          this.toastService.exception(error.title);
          console.error(error);
          reject();
          location.assign('/#budgets');
        });
    });
  }
  _renderApprovalFlow(budgetId) {
    if (window.innerWidth >= 768) {
      this._renderApprovalFlowDesktop(budgetId);
    } else {
      this._renderApprovalFlowMobile(budgetId);
    }

    $('.approval-flow-container').toggleClass('allow-physical-sign', this._allowPhysicalSign);
  }

  _loadConfig() {
    return ConfigService.getInstance()
      .getGlobalConfig()
      .then(x => (this._allowPhysicalSign = x.permitirFirmasFisicas));
  }

  _renderApprovalFlowDesktop(budgetId) {
    if (this._approvalFlowResponse.length == 0) {
      $('.approval-flow-container').addClass('not-found');
      let msgNotFound = document.querySelector('.scroll-budget-requested');
      msgNotFound.innerHTML = `
      <div class="budget-requested all">
        <div class="msg">${TranslationService.instance.translate('bd-apfl-msg-negative')}</div>
        <div class="status">
          <div class="budget-status">
            <i class="circle-state orange"></i>
            <span class="state-txt-approval-flow">${TranslationService.instance.translate(
        BudgetStatusEnum[this._budgetResponse.idEstado]
      )}</span>
          </div>
        </div>
      </div>`;

      this.httpService
        .get(
          `${this.httpService.config.canStartBudgetValidation}?idPresupuesto=
            ${budgetId}&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login
          }`
        )
        .then(response => {
          let btns = document.querySelector('.btns-container');
          btns.innerHTML = `
          <div id="request-approval" class="button with-icon success-button">
            <span class="icon-aprobar"></span>
            <div class="txt">${TranslationService.instance.translate('common-request-ap')}</div>
          </div>`;

          if (!response) {
            $('#request-approval').addClass('disabled');
          }
          else {
            $('#request-approval').click(() => this.requestFlowApproval(this._budgetResponse));
          }
        })
        .catch(error => {
          this.toastService.exception(error.title);
          console.error(error);
          location.assign('/#budgets');
        });
    } else {
      $('.approval-flow-container').removeClass('not-found');
      let budgets = document.querySelector('.scroll-budget-requested');
      budgets.innerHTML = '';
      this._approvalFlowResponse.forEach((e, i) => {
        let date = DatePicker.toLocaleDateString(DatePicker.parseDate(e.fecha));
        let state;

        if (e.idEstadoAprobacion == 1) {
          state = 'orange';
        } else if (e.idEstadoAprobacion == 2) {
          state = 'yellow';
        } else {
          state = 'green';
        }
        budgets.innerHTML += `
      <div class="budget-requested">
        <div class="data date">${date}</div>
        <div class="data action">${i == 0
            ? TranslationService.instance.translate('af-initial-request')
            : TranslationService.instance.translate('af-step') + i
          }</div>
        <div class="data responsable">${e.idUsuarioResponsableNavigationNombre != null
            ? e.idUsuarioResponsableNavigationNombre
            : ''
          }<span>${e.idUsuarioResponsableNavigationCargo != null ? e.idUsuarioResponsableNavigationCargo : ''
          }</span></div>
        <div class="data sign">${e.idUsuarioFirmaNavigationNombre != null ? e.idUsuarioFirmaNavigationNombre : ''
          }<span>${e.idUsuarioFirmaNavigationCargo != null ? e.idUsuarioFirmaNavigationCargo : ''
          }</span></div>
        <div class="data document">${e.urlDocumentoFirma != null
            ? '<a href="' +
            e.urlDocumentoFirma +
            '" target="_blank"><span class="icon-documento"></span></a>'
            : ''
          }</div>
        <div class="data status">
          <div class="budget-status">
            <i class="circle-state ${BudgetFlowStatusClassesEnum[e.idEstadoAprobacion]}"></i>
            <span class="state-txt-approval-flow">${this.getStatusDescription(e)}</span>
          </div>
        </div>
        <div class="data comments">${e.comentarios}</div>
      </div>
      `;
      });
      if (this._budgetResponse.idEstado == 2) {
        this.httpService
          .get(
            `${this.httpService.config.canApproveBudget
            }?idPresupuesto=${budgetId}&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login
            }`
          )
          .then(canUserApprove => {
            this.canApproveOrReject = canUserApprove;
          });
        this.httpService
          .get(
            `${this.httpService.config.canApproveOrRejectBudget
            }?idPresupuesto=${budgetId}&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login
            }`
          )
          .then(response => {
            let btns = document.querySelector('.btns-container');
            btns.innerHTML = `
            <div id="btn-apply-for" class="button with-icon success-button">
              <span class="icon-aprobar"></span>
              <div class="txt">${TranslationService.instance.translate(
              'bd-apfl-btn-apply-for'
            )}</div>
            </div>
            <div id="btn-review-for" class="button with-icon warning-button">
              <span class="icon-cerrar"></span>
              <div class="txt">${TranslationService.instance.translate(
              'bd-apfl-btn-review-for'
            )}</div>
            </div>
            <div id="btn-to-refuse" class="button with-icon danger-button">
              <span class="icon-cerrar"></span>
              <div class="txt">${TranslationService.instance.translate(
              'bd-apfl-btn-to-refuse'
            )}</div>
            </div>
          `;

            if (response) {
              $('#btn-apply-for').click(() =>
                this.approveFlowApproval(
                  this._approvalFlowResponse[this._approvalFlowResponse.length - 1]
                )
              );
              $('#btn-review-for').click(() =>
                this.reviewFlowApproval(
                  this._approvalFlowResponse[this._approvalFlowResponse.length - 1]
                )
              );
              $('#btn-to-refuse').click(() =>
                this.rejectFlowApproval(
                  this._approvalFlowResponse[this._approvalFlowResponse.length - 1].idAprobacion
                )
              );
            }
            else {
              $('#btn-apply-for').addClass('disabled');
              $('#btn-review-for').addClass('disabled');
              $('#btn-to-refuse').addClass('disabled');
            }
          })
          .catch(error => {
            this.toastService.exception(error.title);
            console.error(error);
            location.assign('/#budgets');
          });
        //Disable buttons if the user logged in not the current approver
        if (
          this._approvalFlowResponse[this._approvalFlowResponse.length - 1].idUsuarioResponsable !=
          this.authenticationService.getAuthenticatedUser().idUsuario
        ) {
          $('#btn-apply-for').addClass('disabled');
          $('#btn-to-refuse').addClass('disabled');
        }
      }
      if (this._budgetResponse.idEstado == 3) {
        $('#btn-apply-for').hide();
        $('#btn-to-refuse').hide();
      }
      if (this._budgetResponse.idEstado == 4) {
        let btns = document.querySelector('.btns-container');
        btns.innerHTML = "";
      }
    }
  }
  _renderApprovalFlowMobile() {
    if (this._approvalFlowResponse.length == 0) {
      $('#btn-mob-aprrove-af').hide();
      $('#btn-mob-reject-af').hide();
      $('#btn-mob-revision-af').hide();
      $('#btn-mob-validate-af').click(() => this.requestFlowApproval());
    } else {
      let html = '';
      //Hide button to validate
      $('#btn-mob-validate-af').hide();
      //Hide empty flow msg
      $('#approval-flow-mobile')
        .find('.msg')
        .hide();
      this._approvalFlowResponse.forEach((flow, index) => {
        let date = DatePicker.toLocaleDateString(DatePicker.parseDate(flow.fecha));
        let state;

        if (flow.idEstadoAprobacion == 1) {
          state = 'orange';
        } else if (flow.idEstadoAprobacion == 2) {
          state = 'yellow';
        } else {
          state = 'green';
        }
        html += `
        <div class="card">
          <div class="card-row">
            <span class="title">${index == 0
            ? TranslationService.instance.translate('af-initial-request')
            : TranslationService.instance.translate('af-step') + index
          }</span>
            ${flow.urlDocumentoFirma != null
            ? '<a href="' + flow.urlDocumentoFirma + '" class="icon icon-documento"></a>'
            : ''
          }
          </div>
          <div class="card-row">
            <div class="card-row-element half">
              <span class="label">${TranslationService.instance.translate('common-date')}</span>
              <span class="description">${date}</span>
            </div>
            <div class="card-row-element half">
              <span class="label">${TranslationService.instance.translate('common-state')}</span>
              <div class="state-container">
                <i class="circle-state center ${RequestFlowStatusClassesEnum[flow.idEstadoAprobacion]
          }"></i>
                <span class="description right">${TranslationService.instance.translate(
                  FlowStatusEnum[flow.idEstadoAprobacion]
          )}</span>
              </div>
            </div>
          </div>
          <div class="card-row">
            <div class="card-row-element half">
              <span class="label">${TranslationService.instance.translate(
            'common-responsable'
          )}</span>
              <span class="description ellipsis">${flow.idUsuarioResponsableNavigationNombre != null
            ? flow.idUsuarioResponsableNavigationNombre
            : '-'
          }</span>
              <span class="subdescription ">${flow.idUsuarioResponsableNavigationCargo != null
            ? flow.idUsuarioResponsableNavigationCargo
            : '-'
          }</span>
            </div>
            <div class="card-row-element half">
              <span class="label">${TranslationService.instance.translate('common-sign')}</span>
              <span class="description">${flow.idUsuarioFirmaNavigationNombre != null
            ? flow.idUsuarioFirmaNavigationNombre
            : '-'
          }</span>
            </div>
          </div>
          <div class="card-row">
            <div class="card-row-element all">
              <span class="label">${TranslationService.instance.translate('common-comments')}</span>
              <span class="description">${flow.comentarios != null ? flow.comentarios : '-'}</span>
            </div>
          </div>
          </div>`;
      });
      //Add cards html to cards container
      $('#approval-flow-cards-container').html(html);
      //Bind click buttons events
      $('#btn-mob-validate-af').click(() => this.requestFlowApproval());
      $('#btn-mob-aprrove-af').click(() =>
        this.approveFlowApproval(this._approvalFlowResponse[this._approvalFlowResponse.length - 1])
      );
      $('#btn-mob-reject-af').click(() =>
        this.rejectFlowApproval(
          this._approvalFlowResponse[this._approvalFlowResponse.length - 1].idAprobacion
        )
      );
      $('#btn-mob-revision-af').click(() =>
      this.reviewFlowApproval(
        this._approvalFlowResponse[this._approvalFlowResponse.length - 1]
      )
    );
      //Disable buttons if the user logged in not the current approver
      if (
        this._approvalFlowResponse[this._approvalFlowResponse.length - 1].idUsuarioResponsable !=
        this.authenticationService.getAuthenticatedUser().idUsuario
      ) {
        $('#btn-mob-aprrove-af').addClass('disabled');
        $('#btn-mob-reject-af').addClass('disabled');
        $('#btn-mob-revision-af').addClass('disabled');
      }

      //Hide buttons if is not pending of approval
      if (this._budgetResponse.idEstado == BudgetStatusNameEnum.PendindApproval) {
        $('#btn-mob-aprrove-af').show();
        $('#btn-mob-reject-af').show();
        $('#btn-mob-revision-af').show();
      } else {
        $('#btn-mob-aprrove-af').hide();
        $('#btn-mob-reject-af').hide();
        $('#btn-mob-revision-af').hide();
      }
    }
  }

  /**
   *
   * @param {*} budgetResponse
   */
  requestFlowApproval() {
    if (this.isDirty) {
      this.toastService.error(
        TranslationService.instance.translate('bd-require-save-title'),
        TranslationService.instance.translate('bd-require-save-content')
      );
    } else {
      let modalOptions = new RequestFlowApprovalOptions();
      modalOptions.flowType = 'budget';
      modalOptions.validateObjectId = this._budgetResponse.idPresupuesto;
      modalOptions.userId = this.authenticationService.getAuthenticatedUser().login;
      let modal = new ModalComponent(
        TranslationService.instance.translate('common-request-ap'),
        new RequestFlowApprovalComponent(modalOptions)
      );
      modal.onClosed.listen(() => {
        if (modal.result == null) return;
        this.loadData(this.budgetManager.budgetId);
      });
    }
  }

  /**
   * Trigger modal for approve or validate the flow
   * @param {*} budgetResponse
   */
  approveFlowApproval(lastApproval) {
    let modalOptions = new ApproveValidationFlowOptions();
    modalOptions.flowType = 'budget';
    modalOptions.approvalId = lastApproval.idAprobacion;
    modalOptions.validateObjectId = this._budgetResponse.idPresupuesto;
    modalOptions.userId = this.authenticationService.getAuthenticatedUser().login;
    modalOptions.requiredSignerId = lastApproval.idUsuarioFirma;
    modalOptions.isReview = lastApproval.esRevision;
    modalOptions.canApproveOrReject = this.canApproveOrReject;
    let modal = new ModalComponent(
      TranslationService.instance.translate('bd-modal-apfor-title'),
      new ApproveValidationFlowComponent(modalOptions)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this.loadData(this.budgetManager.budgetId);
    });
  }

  /**
  * Trigger modal for approve or validate the flow
  * @param {*} budgetResponse
  */
  reviewFlowApproval(lastApproval) {
    let modalOptions = new ApproveValidationFlowOptions();
    modalOptions.flowType = 'budget';
    modalOptions.approvalId = lastApproval.idAprobacion;
    modalOptions.validateObjectId = this._budgetResponse.idPresupuesto;
    modalOptions.userId = this.authenticationService.getAuthenticatedUser().login;
    modalOptions.requiredSignerId = lastApproval.idUsuarioFirma;
    modalOptions.isReview = true;
    modalOptions.canApproveOrReject = false;
    let modal = new ModalComponent(
      TranslationService.instance.translate('bd-modal-review-budget-title'),
      new ApproveValidationFlowComponent(modalOptions)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this.loadData(this.budgetManager.budgetId);
    });
  }

  rejectFlowApproval(approvalId) {
    let options = new RejectValidationFlowOptions();
    options.approvalId = approvalId;
    options.flowType = FlowType.Budget;
    let modal = new ModalComponent(
      TranslationService.instance.translate('bd-modal-reject-title'),
      new RejectValidationFlowComponent(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      $('.circle-state').removeClass('yellow');
      this.loadData(this.budgetManager.budgetId);
    });
  }

  getStatusDescription(e) {
    let translationKey = '';

    if (e.esRevision) {
      switch (e.idEstadoAprobacion) {
        case 2:
          translationKey = 'review';
          break;
        case 3:
          translationKey = 'review-requested';
          break;
        default:
          translationKey = FlowStatusEnum[e.idEstadoAprobacion];
          break;
      }
    } else {
      translationKey = FlowStatusEnum[e.idEstadoAprobacion];
    }

    return TranslationService.instance.translate(translationKey);
  }

  buildTitle() {
    var innnerHtml = '';
    innnerHtml = innnerHtml.concat(
      TranslationService.instance.translate('common-exercises') +
      ' ' +
      this._budgetResponse.areaTrabajo.ejercicio +
      ' / ' +
      this._budgetResponse.areaTrabajo.empresa +
      ' / ' +
      this._budgetResponse.areaTrabajo.areaInversion
    );
    $('#view-title').html(innnerHtml);
  }

  buildHead() {
    // State:
    $('.budget-status-text').text(this._budgetResponse.esRevision
      ? TranslationService.instance.translate('review')
      : TranslationService.instance.translate(BudgetStatusEnum[this._budgetResponse.idEstado]
      )
    );
    $('.circle-state').addClass(BudgetStatusClassesEnum[this._budgetResponse.idEstado]);

    // Badge:
    let badgeId = this._budgetResponse.idMoneda;
    this._localCurrencyId = this._budgetResponse.idMoneda;
    this._euroCurrencyId = this._budgetResponse.monedasPresupuesto.find(
      x => x.nombreCorto === 'EUR'
    ).idTipoMoneda;
    let localBadge = this._budgetResponse.monedasPresupuesto.filter(
      m => m.idTipoMoneda === badgeId
    )[0];
    let html = `<span id="badge-item-local">${localBadge.nombreLargoPlural} (${localBadge.simbolo})</span>`;
    if (localBadge.nombreCorto != 'EUR') {
      html += `<span id="badge-item-euros">Euros (€)</span>`;
    }
    $('#badges-selector').html(html);
    $('#badge-item-local')
      .off()
      .click(() => this.switchCurrency(true));
    if (localBadge.nombreCorto != 'EUR') {
      $('#badge-item-euros')
        .off()
        .click(() => this.switchCurrency(false));
    }
    $('#badge-item-local').addClass('active');

    // Units:
    $('#unit-units').addClass('active');
  }

  /**
   * Can modify when it's draft or when it's pending for approval and he is the responsable.
   */
  _isReadOnly() {
    let lastApproval =
      this._approvalFlowResponse.length > 0
        ? this._approvalFlowResponse[this._approvalFlowResponse.length - 1]
        : null;

    return (
      this._budgetResponse.idEstado != BudgetStatusNameEnum.Draft &&
      !(
        this._budgetResponse.idEstado == BudgetStatusNameEnum.PendindApproval &&
        lastApproval != null &&
        lastApproval.idUsuarioResponsable ==
        this.authenticationService.getAuthenticatedUser().idUsuario
      )
    );
  }

  buildContent() {
    var options = new BudgetTreeOptions();
    this.budgetManager = new BudgetManager(
      this._budgetResponse.idPresupuesto,
      this.authenticationService.getAuthenticatedUser().login,
      5
    );
    this.budgetManager.loadJsonItems(
      this._budgetResponse.lineasPresupuesto,
      this._budgetResponse.areaTrabajo.ejercicio
    );
    options.budgetManager = this.budgetManager;
    options.allowDrag = true;
    options.userId = this.authenticationService.getAuthenticatedUser().login;
    options.readonly = this._isReadOnly();
    options.selector = '.budget-tree';
    options.companyId = this._budgetResponse.areaTrabajo.idEmpresa;
    options.investmentAreaId = this._budgetResponse.areaTrabajo.idAreaInversion;
    options.estructuradoEnUte = this._budgetResponse.areaTrabajo.estructuradoEnUte;
    options.pdfName = `${this._budgetResponse.areaTrabajo.ejercicio}-${this._budgetResponse.areaTrabajo.empresa}-${this._budgetResponse.areaTrabajo.areaInversion}.pdf`;
    options.euroCurrencyId = this._euroCurrencyId;
    options.urlPDF = this._budgetResponse.urlPDF;

    this.budgetTree = new BudgetTree(options);
    this.budgetTree.editedValue.listen(() => this.budgetEditedCallback());
    this.budgetTree.budgetSaved.listen(() => this.reloadBudget());
    this.budgetTree.askInfoDone.listen(() => this.reloadBudget());
    if (options.readonly == true) {
      $('#btn-modal-create-line').attr('disabled', true);
      $('#btn-modal-edit-line').attr('disabled', true);
      $('#btn-modal-clean-line').attr('disabled', true);
      $('#btn-modal-ask-info').attr('disabled', true);
      $('#btn-modal-delete-line').attr('disabled', true);
      $('#btn-delete-budget').addClass('disabled', true);
    }

    this.rendererCurrency = null;
    this.rendererUnit = null;
    this.budgetTree.beforeChangeRenderer.listen(() => this._beforeChangeRenderer());
    this.budgetTree.afterChangeRenderer.listen(() => this._afterChangeRenderer());
  }

  _beforeChangeRenderer() {
    this.rendererCurrency = this.budgetTree.currentCurrency;
    this.rendererUnit = this.budgetTree.currentUnit;
  }
  _afterChangeRenderer() {
    if (this.rendererCurrency != null) {
      this.switchCurrency(this.rendererCurrency, true);
    }
    if (this.rendererUnit != null) {
      this.switchUnit(this.rendererUnit, true);
    }
  }

  enableButtons() {
    $('#btn-modal-create-line')
      .off()
      .click(() => this.budgetTree.createNewLine());
    $('#btn-modal-edit-line')
      .off()
      .click(() => this.budgetTree.editLine());
      $('#btn-modal-clean-line')
      .off()
      .click(() => this.budgetTree.cleanLine());
    $('#btn-modal-ask-info')
      .off()
      .click(() => this.budgetTree.askInfo());
    $('#btn-modal-delete-line')
      .off()
      .click(() => this.budgetTree.deleteLine());
    $('#btn-save')
      .off()
      .click(() => this.budgetTree.saveBudget());
    $('#btn-save').attr('disabled', true);
    $('#btn-ap-fl')
      .off()
      .click(() => this.actionApprovalFlow());
    $('.budgetdetails-container')
      .off()
      .click(e => {
        if ($(e.target).parents('#btn-options').length > 0 || e.target.id == 'btn-options') {
          $('#context-menu').toggleClass('active');
        } else {
          $('#context-menu').removeClass('active');
        }
      });
    $('#btn-delete-budget')
      .off()
      .click(() => this.budgetTree.deleteBudget(this._budgetResponse.idPresupuesto));
    $('#btn-download-pdf')
      .off()
      .click(() => {
        var timeOut = 0;
        timeOut = this.infoDownload(this._budgetResponse.idEstado, this._localCurrencyId);
        setTimeout(() => {
          this.budgetTree.downloadPDF(this._budgetResponse.idPresupuesto)
        }, timeOut);
      }
      );
    $('#unit-units')
      .off()
      .click(() => this.switchUnit(UnitsEnums.Unit));
    $('#unit-thousands')
      .off()
      .click(() => this.switchUnit(UnitsEnums.Thousand));
    $('#unit-millions')
      .off()
      .click(() => this.switchUnit(UnitsEnums.Millions));
    //Mobile
    $('#btn-toogle-menu').click(() => this._toggleMenu());
    $('#details-tab').click(() => this.detailsTab());
    $('#approval-flow-tab').click(() => this.approvalFlowTab());
    $('.download-pdf')
      .off()
      .click(() => {
        var timeOut = 0;
        timeOut = this.infoDownload(this._budgetResponse.idEstado, this._localCurrencyId);
        setTimeout(() => {
          this.budgetTree.downloadPDF(this._budgetResponse.idPresupuesto)
        }, timeOut);
      });

    $('#btn-modal-ask-info').attr('disabled', true);
    $('#btn-modal-edit-line').attr('disabled', true);
    $('#btn-modal-clean-line').attr('disabled', true);
  }

  infoDownload(status = -1, currency = 0) {
    var timeOut = 0;
    var localCurrency = this._isLocalCurrency;
    if (status == 3 && currency != 0) {
      this.toastService.info(
        TranslationService.instance.translate('info-title'),
        TranslationService.instance.translate('msg-info-budget-local')
      );
      timeOut = 3000;
    }
    if (status == 2 && (currency != 0 && localCurrency)) {
      this.toastService.info(
        TranslationService.instance.translate('info-title'),
        TranslationService.instance.translate('msg-info-budget-sign-local')
      );
      timeOut = 3000;
    }
    return timeOut;
  }

  /**
   * Callback triggered from budgetTree when the budget has been saved correctly or when it's asked information.
   */
  reloadBudget() {
    this.isDirty = false;
    this.router.preventDefault = false;
    $('#btn-visualization-options').removeClass('disabled');
    $('#btn-download-pdf').attr('disabled', false);
    $('.download-pdf').toggleClass('disabled', false);
    this.loadData(this.budgetManager.budgetId);
  }

  /**
   * Function triggered when a cell is edited in budget tree or an amount is dirty on dirsbursement dates modal
   */
  budgetEditedCallback() {
    $('#btn-save').attr('disabled', false);
    $('#btn-modal-ask-info').attr('disabled', true);
    $('#btn-download-pdf').attr('disabled', true);
    $('.download-pdf').toggleClass('disabled', true);

    // In addition a listener for the location change is created
    this.router.preventDefault = true;
    this.isDirty = true;
  }

  /**
   * Open modal asking the user for confirmation before leave the page when a change has been done
   * @param {*} event
   */
  confirmQuitWithoutSave(event) {
    if (!this.isDirty) {
      this.router.preventDefault = false;
      return;
    }

    this.router.cancelNavigation = true;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    let desiredLocation = location.hash;
    //Assign current location to loction of this budget details
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
      location.assign('#budgets/' + this.budgetManager.budgetId);
    } else {
      location.assign(event.oldURL.substr(event.oldURL.indexOf('#')));
    }
    var modal = new ModalComponent(
      TranslationService.instance.translate('common-wout-continue'),
      new QuitWithoutSaveComponent()
    );
    modal.onClosed.listen(() => {
      if (modal.result != null && modal.result.save) {
        // save budget
        $(window).off('beforeunload');
        this.router.preventDefault = false;
        this.router.cancelNavigation = false;
        this.budgetTree.saveBudget(this.budgetManager.getBudgetId());
      } else if (modal.result != null && modal.result.continue) {
        //continue to target
        $(window).off('beforeunload');
        this.router.preventDefault = false;
        this.router.cancelNavigation = false;
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
          location.assign(desiredLocation);
        } else {
          location.assign(event.newURL.substr(event.newURL.indexOf('#')));
        }
      }
    });
  }

  switchCurrency(isLocal, windowResize) {
    if (this.isDirty) {
      if (!windowResize) {
        // When user edit budget is needed to save budget before switch to Euros view.
        this.toastService.info(
          TranslationService.instance.translate('info-title'),
          TranslationService.instance.translate('msg-save-required')
        );
      }
      return;
    }

    $('[id^=badge-item-]').removeClass('active');
    if (isLocal) {
      $('#badge-item-local').addClass('active');
    } else {
      $('#badge-item-euros').addClass('active');
    }
    this._isLocalCurrency = isLocal;
    this.budgetTree.switchCurrency(isLocal);
  }

  switchUnit(unit, windowResize) {
    if (this.isDirty) {
      if (!windowResize) {
        // When user edit budget is needed to save budget before switch to Euros view.
        this.toastService.info(
          TranslationService.instance.translate('info-title'),
          TranslationService.instance.translate('msg-save-required')
        );
      }
      return;
    }

    $('.unit').removeClass('active');
    switch (unit) {
      case UnitsEnums.Unit:
        $('#unit-units').addClass('active');
        break;
      case UnitsEnums.Thousand:
        $('#unit-thousands').addClass('active');
        break;
      case UnitsEnums.Millions:
        $('#unit-millions').addClass('active');
        break;
    }
    this.budgetTree.switchUnit(unit);
  }

  actionApprovalFlow() {
    if ($('.approval-flow').hasClass('active')) {
      $('.approval-flow').removeClass('active');
    } else {
      $('.approval-flow').addClass('active');
    }
  }

  _toggleMenu() {
    $('.sidenav').toggleClass('menuComplete');
  }
  approvalFlowTab() {
    //Hide details content
    $('.budget-tree').addClass('hidden');
    //Show approval flow content
    $('.approval-flow-mobile').removeClass('hidden');
    //Remove active from details tab
    $('#details-tab').removeClass('active');
    //Add active to approval flow tab
    $('#approval-flow-tab').addClass('active');
  }
  detailsTab() {
    //Hide approval flow content
    $('.budget-tree').removeClass('hidden');
    //Show details content
    $('.approval-flow-mobile').addClass('hidden');
    //Add active from details tab
    $('#details-tab').addClass('active');
    //Remove active to approval flow tab
    $('#approval-flow-tab').removeClass('active');
  }
}
