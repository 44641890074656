import './approveValidationFlow.scss';
import { HttpService } from '../../core/http/http';
import { ToastService } from '../../shared/services/toastService';
import { ConfigService } from '../../shared/services/config';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { Validator } from '../../core/validator/validator';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { ViewsLoader } from '../../core/views/views-loader';
import {
  InputFileComponent,
  InputFileOptions
} from '../../shared/components/input-file/input-file';
import { AuthenticationService } from '../../shared/services/authenticationService';
import { TranslationService } from '../../core/translation/translation';

var htmlContent = require('./approveValidationFlow.html');

const renderMarkup = () => htmlContent;

export class ApproveValidationFlowComponent extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this._http = new HttpService();
    this._toasts = new ToastService();
    this._options = options || new ApproveValidationFlowOptions();
    this._viewLoader = new ViewsLoader();
    this._config = ConfigService.getInstance();
    this._allowPhysicalSign = null;
    this._defaultValues = null;
    this._allowMatrix = true;
    this._idUsuarioPosterior = null;
    this._isMatrixApproval = null;
    this._auth = new AuthenticationService();
  }

  onOpened() {
    this._init();
  }

  _init() {
    this.parentModal.container
      .find('.approve-validation-flow-component')
      .addClass(this._options.flowType);

    let approveTextKey;

    if (this._options.isReview) {
      approveTextKey = this._options.flowType === 'budget'
        ? 'bd-apfl-btn-review-for'
        : 'req-apfl-btn-to-review';
      this.parentModal.container.find('.save').addClass("warning-button");
    } else {
      approveTextKey = this._options.flowType === 'budget'
        ? 'bd-modal-apfor-validate-budget'
        : 'bd-modal-apfor-validate-request';
    }

    this.parentModal.container
      .find('.approve-button-text')
      .text(TranslationService.instance.translate(approveTextKey));

    this._saveButton = this.parentModal.container.find('.save');
    this._cancelButton = this.parentModal.container.find('.cancel');

    this._finalStep = this.parentModal.container.find('.final-step');
    this._physicalSign = this.parentModal.container.find('.physical-sign');
    this._defaultValuesChk = this.parentModal.container.find('.default-values');
    this._filename = this.parentModal.container.find('.filename');
    this._comentarios = this.parentModal.container.find('.comments');

    this._physicalSignGroup = this.parentModal.container.find('.physical-sign-group');
    this._middleStepGroup = this.parentModal.container.find('.middle-step-group');
    this._signerGroup = this.parentModal.container.find('.signer-group');
    this._signedGroup = this.parentModal.container.find('.signed-by-group');

    this._middleStepGroup
      .find('.subtitle')
      .html(
        this._options.flowType == 'budget'
          ? TranslationService.instance.translate('bd-modal-apfor-subtitle')
          : TranslationService.instance.translate('req-detail-modal-validate-description')
      );

    let attachmentOptions = new InputFileOptions();
    attachmentOptions.rules = { required: '', 'data-validator-ignore-invisible': '' };
    attachmentOptions.allowDelete = true;
    attachmentOptions.allowMultipleFiles = false;
    this._attachments = new InputFileComponent(attachmentOptions);
    this._attachments.onChange.listen(() => {
      this._validator.validate();
    });
    this._viewLoader.loadView(this._attachments, document.getElementById('sign-file'));

    this._responsablesSelector = new DropDownComponent(
      document.getElementById('responsable-filter')
    );
    this._responsablesSelector.setRules({
      required: true,
      'data-validator-ignore-invisible': ''
    });
    this._responsablesSelector.loadData(this._users, 'idUsuario', 'nombre');

    this._responsableMatrix1Filter = this.parentModal.container.find('#responsable-filter-matrix-1');
    this._responsableMatrix2Filter = this.parentModal.container.find('#responsable-filter-matrix-2');
    this._matrixApproval = this.parentModal.container.find('.matrix-approval');
    this._matrixApprovalGroup = this.parentModal.container.find('.matrix-approval-group');
    this._matrixGroup = this.parentModal.container.find('.matrix-group');

    this._responsableMatrixPosterior = this.parentModal.container.find(".responsable-matrix-posterior");

    this._responsableMatrix1Selector = new DropDownComponent(
      document.getElementById('responsable-filter-matrix-1')
    );
    this._responsableMatrix2Selector = new DropDownComponent(
      document.getElementById('responsable-filter-matrix-2')
    );
    this._responsableMatrix1Selector.loadData(this._users, 'idUsuario', 'nombre');
    this._responsableMatrix2Selector.loadData(this._users, 'idUsuario', 'nombre');

    this._physicalSignerSelector = new DropDownComponent(document.getElementById('signer-filter'));
    this._physicalSignerSelector.setRules({
      required: true,
      'data-validator-ignore-invisible': ''
    });
    this._physicalSignerSelector.loadData(this._users, 'idUsuario', 'nombre');
    this._signedBySelector = new DropDownComponent(document.getElementById('signed-by-filter'));
    this._signedBySelector.loadData(this._users, 'idUsuario', 'nombre');
    this._signedBySelector.setRules({
      required: true,
      'data-validator-ignore-invisible': ''
    });

    this._saveButton.click(() => this.save());
    this._cancelButton.click(() => this.parentModal.close());

    this._finalStep.change(() => this._changedFinalStep());
    this._physicalSign.change(() => this._changedPhysicalSign());
    this._matrixApproval.change(() => this._changedMatrixApproval());


    this._hideFields();
    this._setDefault();
    this._validatorInit();
  }

  _hideFields() {
    if (!this._allowPhysicalSign || this._options.isReview) {
      this._physicalSignGroup.addClass('hidden');
      this._signerGroup.addClass('hidden');
    }

    if (!this._options.requiredSignerId || this._options.isReview) {
      this._signedGroup.addClass('hidden');
    }

    if (!this._allowMatrix) {
      this._matrixApprovalGroup.addClass('hidden');
      this._matrixGroup.addClass('hidden');
    }
  }

  _validatorInit() {
    this._validator = new Validator(this.parentModal.container);
    this._validator.validate();
  }

  _setDefault() {
    if (this._defaultValues != null && !this._isMatrixApproval) {
      if (!this._options.isReview) {
        if (this._allowPhysicalSign && this._defaultValues.solicitarFirma) {
          this._physicalSign.prop('checked', this._defaultValues.solicitarFirma).trigger('change');

          this._physicalSignerSelector.setSelected(
            this._defaultValues.idResponsableFirma,
            'idUsuario',
            'nombre'
          );
        }
      }

      if (this._options.isReview) {
        this._responsablesSelector.setSelected(
          this._defaultValues.idUsuarioSolicitante,
          'idUsuario',
          'nombre'
        );
      }
      else if (this._defaultValues.idResponsable != null) {
        this._responsablesSelector.setSelected(
          this._defaultValues.idResponsable,
          'idUsuario',
          'nombre'
        );
      } else {
        this._finalStep.prop('checked', this._options.flowType === 'budget' ? this._defaultValues.idAccionPresupuesto : this._defaultValues.idAccionSolicitud == 1).trigger('change');
      }

      if (!this._options.canApproveOrReject) {
        this._finalStep.prop('checked', false).trigger('change');
      } else {
        this._comentarios.prop('required', false);
      }

      if (this._defaultValues.idAprobadorMatricial1 != null && this._defaultValues.idAprobadorMatricial2 != null) {
        this._matrixApproval.prop('checked', true).trigger('change');
        this._responsableMatrix1Selector.setSelected(
          this._defaultValues.idAprobadorMatricial1,
          'idUsuario',
          'nombre'
        );
        this._responsableMatrix2Selector.setSelected(
          this._defaultValues.idAprobadorMatricial2,
          'idUsuario',
          'nombre'
        );
      }

      this.parentModal.container.find('.subtitle').toggleClass('hidden', this._options.isReview || !this._options.canApproveOrReject);
      this.parentModal.container.find('.final-step').parent().parent().toggleClass('hidden', this._options.isReview || !this._options.canApproveOrReject);
      this.parentModal.container.find('.checkbox-container').toggleClass('hidden', this._options.isReview);
    }
    else if (this._isMatrixApproval) {
      this.parentModal.container.find('.hiddenMatrix').addClass('hidden');
      this._responsablesSelector.setSelected(
        this._idUsuarioPosterior,
        'idUsuario',
        'nombre'
      );

      if (!this._options.isReview) {
        this.parentModal.container.find('#responsable-filter').find("input").prop('disabled', true);
        this.parentModal.container.find('#responsable-filter').find(".chevron").remove();
      }
    }

    let signerDefault =
      this._options.requiredSignerId || this._auth.getAuthenticatedUser().idUsuario;
    this._signedBySelector.setSelected(parseInt(signerDefault), 'idUsuario', 'nombre');
  }

  onLoading(callback) {
    Promise.all([this._loadConfig(), this._loadFlowConfig(), this._loadUsers(), this._loadMatrixConfig(), this._loadIsMatrixApproval()]).then(() => {
      callback();
    });
    return true;
  }

  _changedFinalStep() {
    this._middleStepGroup.toggleClass('hidden', this._finalStep.is(':checked'));

    if (this._validator != undefined) {
      this._validator.validate();
    }
  }
  _changedPhysicalSign() {
    this._signerGroup.toggleClass('hidden', !this._physicalSign.is(':checked'));

    if (this._validator != undefined) {
      this._validator.validate();
    }
  }

  _changedMatrixApproval() {

    if (this._matrixApproval.is(':checked')) {
      this._responsableMatrixPosterior
        .find('.txt')
        .html(
          TranslationService.instance.translate('common-responsable-matrix-posterior')
        );
    } else {
      this._responsableMatrixPosterior
        .find('.txt')
        .html(
          TranslationService.instance.translate('common-responsable')
        );
    }

    this._matrixGroup.toggleClass('hidden', !this._matrixApproval.is(':checked'));
    this._responsableMatrix1Selector.setRule('required', this._matrixApproval.is(':checked'));
    this._responsableMatrix2Selector.setRule('required', this._matrixApproval.is(':checked'));
    if (this._validator != undefined) {
      this._validator.validate();
    }
  }

  _loadUsers() {
    return this._http
      .get(this._http.config.loadUsers)
      .then(response => {
        this._users = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadConfig() {
    return this._config
      .getGlobalConfig()
      .then(x => (this._allowPhysicalSign = x.permitirFirmasFisicas));
  }

  _loadFlowConfig() {
    return this._config
      .getFlowConfig(this._options.validateObjectId, this._options.flowType)
      .then(x => (this._defaultValues = x));
  }

  _loadMatrixConfig() {
    return this._config
      .getMatrixConfig(this._options.validateObjectId, this._options.flowType)
      .then(x => (this._allowMatrix = x));
  }

  _loadIsMatrixApproval() {
    return this._config
      .getIsMatrixApproval(this._options.validateObjectId, this._options.flowType)
      .then(x => (this._isMatrixApproval = x.esAprobacionMatricial, this._idUsuarioPosterior = x.idProximoResponsable));
  }

  _sendRequestFlowApproval(url, request) {
    this.parentModal.toggleLoadingIcon(true);

    return this._http
      .post(url, request)
      .then(response => {
        this._toasts.success(
          TranslationService.instance.translate('success-title'),
          this._options.isReview ? TranslationService.instance.translate(this._options.flowType + '-flow-review') : TranslationService.instance.translate(this._options.flowType + '-flow-approved')
        );

        this.value = { success: true };
        this.parentModal.close();
      })
      .catch(error => {
        if (error.message.indexOf("innerHTML") == -1) {
          this._toasts.exception(error.title, error.message);
          console.error(error);
        }
      })
      .finally(() => {
        this.parentModal.toggleLoadingIcon(false);
      });
  }

  _getUrl() {
    switch (this._options.flowType) {
      case 'budget':
        return this._http.config.approveBudget;
      case 'request':
        return this._http.config.approveRequest;
      default:
        throw 'not valid request type';
    }
  }

  _buildBody() {
    let request = new RequestFlowApprovalApi();
    request.idAprobacion = this._options.approvalId;
    request.etapaFinal = this._finalStep.is(':checked');
    request.comentarios = this._comentarios.val() ? this._comentarios.val() : "";
    request.usuarioActualizacion = this._options.userId;
    request.esRevision = this._options.isReview;

    request.matrixApproval = this._matrixApproval.is(':checked');
    request.idUsuarioMatrix1 = this._responsableMatrix1Selector.getValue();
    request.idUsuarioMatrix2 = this._responsableMatrix2Selector.getValue();

    if (this._options.requiredSignerId) {
      request.idFirmadoPor = this._signedBySelector.getValue();
    }

    if (!request.etapaFinal) {
      request.idAprobadorSiguienteEtapa = this._responsablesSelector.getValue();
      request.conFirmaSiguienteEtapa = this._physicalSign.is(':checked');
      request.establecerComoPasoPorDefecto = this._defaultValuesChk.is(':checked');

      if (request.conFirmaSiguienteEtapa) {
        request.idResponsableFirmaSiguienteEtapa = this._physicalSignerSelector.getValue();
      }
    } else {
      request.idAprobadorSiguienteEtapa = this._auth.getAuthenticatedUser().idUsuario;
    }

    if (this._options.flowType == 'budget') {
      request.idPresupuesto = this._options.validateObjectId;
    } else {
      request.idSolicitud = this._options.validateObjectId;
    }

    let formData = HttpService.objectToFormData(request);

    formData.append('documentoFirma', this._attachments.files[0]);

    return formData;
  }

  save() {
    if (!this._validator.isValid) {
      return;
    }

    let url = this._getUrl();
    let body = this._buildBody();

    this._sendRequestFlowApproval(url, body);
  }

  get result() {
    return this.value;
  }
}

export class ApproveValidationFlowOptions {
  userId = null;
  approvalId = null;
  validateObjectId = null;
  requiredSignerId = null;
  isReview = false;
  /**
   * Create the request flow approval for a budget or request
   */
  flowType = null;
}

class RequestFlowApprovalApi {
  idAprobacion = null;
  etapaFinal = false;
  esRevision = false;
  establecerComoPasoPorDefecto = false;
  idAprobadorSiguienteEtapa = null;
  idResponsableFirmaSiguienteEtapa = null;
  conFirmaSiguienteEtapa = false;
  comentarios = null;
  idFirmadoPor = null;
  usuarioActualizacion = null;
}
