export let FlowStatusEnum = {
  1: 'draft',
  2: 'pending-approval',
  3: 'approved',
  4: 'rejected',
  5: 'review',
  7: 'matricial' //Cambiar 7 por 6
};

export let BudgetStatusEnum = {
  1: 'draft',
  2: 'pending-approval',
  3: 'approved',
  4: 'rejected',
  5: 'closed'
};

export let RequestStatusEnum = {
  1: 'draft',
  2: 'pending-approval',
  3: 'approved',
  4: 'rejected',
  5: 'canceled',
  6: 'review'
};

export let BudgetStatusClassesEnum = {
  1: 'orange',
  2: 'yellow',
  3: 'green',
  4: 'red',
  5: 'gray'
};

export let RequestStatusClassesEnum = {
  1: 'orange',
  2: 'yellow',
  3: 'green',
  4: 'red',
  5: 'gray'
};

export let BudgetFlowStatusClassesEnum = {
  1: 'orange',
  2: 'yellow',
  3: 'green',
  4: 'red',
  5: 'gray'
};

export let RequestFlowStatusClassesEnum = {
  1: 'orange',
  2: 'yellow',
  3: 'green',
  4: 'red',
  5: 'brown'
};

export let PetitionStatusEnum = {
  Pending: 1,
  Completed: 2
};

export let PetitionStatusClassesEnum = {
  1: 'icon-peticion-pending',
  2: 'icon-peticion-recibida'
};

export let BudgetLinesActionsEnums = {
  Add: 0,
  Update: 1,
  Delete: 2
};

export let UnitsEnums = {
  Unit: 0,
  Thousand: 1,
  Millions: 2
};

export let CurrencyTypes = {
  Euros: 1,
  Zlotys: 2,
  Dollars: 3
};

export let ToastTypes = {
  Success: 'success',
  Error: 'error',
  Warning: 'warning',
  Info: 'info'
};

export let PetitionStatusTextEnum = {
  1: 'petitions-pending',
  2: 'petitions-replied',
  3: 'petitions-cancel'
};

export let PetitionStatusDotsEnum = {
  1: 'yellow',
  2: 'green',
  3: 'brown'
};

export let FlowType = {
  Budget: 'budget',
  Request: 'request'
};

export let RequestTypeEnum = {
  Investment: 1,
  Disinvestment: 2
};
export let RequestTypeEnumTranslation = {
  1: 'investment',
  2: 'disinvestment'
};

export let InvestmentTypeEnum = {
  1: 'OnPlan',
  2: 'NotOnPlan'
};

export let BudgetStatusNameEnum = {
  Draft: 1,
  PendindApproval: 2,
  Approved: 3,
  Rejected: 4,
  Confirmed: 5
};
export let RequestStatusNameEnum = {
  Draft: 1,
  PendindApproval: 2,
  Approved: 3,
  Rejected: 4,
  Canceled: 5
};
export let PetitionStatusNameEnum = {
  PendindApproval: 1,
  Approved: 2,
  Rejected: 3
};

export let ApprovalFlowActionsEnum = {
  1: 'config-cp-chk-budget-approve',
  2: 'config-cp-chk-request-validate'
};

export let WorkTypeEnum = {
  1: 'requests-modal-new-request-edification',
  2: 'requests-modal-new-request-civil',
  3: 'config-flow-default-action-all'
};

export let InvestmentEnum = {
  1: 'investment',
  2: 'disinvestment',
  3: 'config-flow-default-action-all'
};

export let LangugesCodesEnum = {
  Spanish: 'es_ES',
  Polski: 'pl_PL',
  English: 'en_US'
};

export let FlowActionEnum = {
  1: 'flow-action-approve',
  2: 'flow-action-validate',
  3: 'flow-action-reject'
};

export let BudgetLinesTypesEnum = {
  Budget: 1,
  Work: 2,
  Group: 3,
  Line: 4
};

export let RequestTypeOfWorkEnum = {
  Building: 1,
  CivilWork: 2
};
